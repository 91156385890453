var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headerNav',{attrs:{"activeIndex":"2","productsIndex":"4"}}),_c('div',{staticClass:"productsBody"},[_c('div',{staticClass:"productsImg"},[_c('div',[_c('router-link',{attrs:{"to":"/contact"}},[_c('el-button',{staticClass:"formBtn",attrs:{"type":"primary"}},[_vm._v("免费咨询")])],1)],1)]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('contactNav'),_c('footerNav')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleImg"},[_c('img',{attrs:{"src":require("../../assets/img/ldyTitle1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantages"},[_c('div',{staticClass:"advantagesDiv"},[_c('img',{attrs:{"src":require("../../assets/img/qianhuiduo1.png")}}),_c('div',{staticClass:"advantagesTitle"},[_vm._v("多企银行通道 无缝对接")])]),_c('div',{staticClass:"advantagesDiv"},[_c('img',{attrs:{"src":require("../../assets/img/qianhuiduo2.png")}}),_c('div',{staticClass:"advantagesTitle"},[_vm._v("跨行资金池 最优配置资金")])]),_c('div',{staticClass:"advantagesDiv"},[_c('img',{attrs:{"src":require("../../assets/img/qianhuiduo3.png")}}),_c('div',{staticClass:"advantagesTitle"},[_vm._v("多维账户管理 多端掌控全局")])]),_c('div',{staticClass:"advantagesDiv"},[_c('img',{attrs:{"src":require("../../assets/img/qianhuiduo4.png")}}),_c('div',{staticClass:"advantagesTitle"},[_vm._v("无U盾跨行转账 定时归集资金")])]),_c('div',{staticClass:"advantagesDiv"},[_c('img',{attrs:{"src":require("../../assets/img/qianhuiduo5.png")}}),_c('div',{staticClass:"advantagesTitle"},[_vm._v("便捷报表分析 智能交易提醒")])]),_c('div',{staticClass:"advantagesDiv",staticStyle:{"display":"flex","justify-content":"center","align-items":"center","font-size":"18px","color":"#999999"}},[_c('div',{staticStyle:{"width":"100px","text-align":"center"}},[_c('img',{attrs:{"src":require("../../assets/img/lianyunpiao6.png")}}),_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._v("开发中")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleImg"},[_c('img',{attrs:{"src":require("../../assets/img/ldyTitle2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"structure"},[_c('img',{attrs:{"src":require("../../assets/img/structureImg5.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleImg"},[_c('img',{attrs:{"src":require("../../assets/img/ldyTitle3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case"},[_c('img',{attrs:{"src":require("../../assets/img/caseImg3.png")}})])
}]

export { render, staticRenderFns }